<template>
  <div style="height: 100%" class="multiple-number">
    <!-- <div> -->
    <v-card
      class="card-shadow content-view-port"
      elevation="3"
      style="border-radius: 15px !important; height: 100%; width: 100%"
    >
      <!-- style="padding-left: 2px; padding-right: 2px;padding-top:unset;padding-right:unset" -->
      <v-container style="padding: unset" grid-list-md text-xs-center fluid class="content-view-port">
        <v-layout dense wrap :class="alignDataCenter" class="content-view-port">
          <template v-for="(item, index) in config.main_number_config">
            <v-flex
              style="text-align-last: center; overflow: hidden;height:100%"
              :xs6="['xs', 'sm'].includes($vuetify.breakpoint.name)"
              :md4="['md', 'lg'].includes($vuetify.breakpoint.name)"
              :key="index"
            >
              <!-- <span :class="showVerticalClass"></span> -->
              <v-list-item
                align="center"
                justify="center"
                two-line
                :dark="false"
                style="height: 100%"
              >
                <div class="mx-auto px-auto">
                  <div>
                    <v-list-item-content>
                      <v-list-item-subtitle style="font-size: 16px">{{
                        item.title
                      }}</v-list-item-subtitle>
                      <v-list-item-title class="main-number-values">
                        <span class="font-weight-light" style="color: grey">{{
                          item.prefix
                        }}</span>
                        {{
                          dataProvider[item.mainNumberField] &&
                          dataProvider[item.mainNumberField].toLocaleString(
                            'en-IN',
                            { maximumFractionDigits: 2 }
                          )
                        }}<span class="font-weight-light" style="color: grey">
                          {{ item.sufix }}</span
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </div>
                </div>
              </v-list-item>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
    </v-card>
  </div>
  <!-- </div> -->
</template>

<script>
import { makeDataProvider } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  data: () => ({
    mainNumber: '',
    dataProvider: {},
    config: {
      main_number_config: []
    }
  }),
  created: function () {
    console.log('========multiple==========', this)
    if (this.configuration)
      this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    if (
      this.dataSource &&
      this.dataSource.headers &&
      this.dataSource.headers.length
    ) {
      if (this.dataSource.rows.length) {
        this.dataProvider = this.dataSource.rows[0]
      } else {
        this.dataProvider = makeDataProvider(this.dataSource)[0]
      }
    }
    // this.dataProvider = this.configuration.dataProvider[0]
    // console.log('this.dataProvider :>> ', this.dataProvider)
    // console.log(
    //   'this.configuration:>> ',
    //   Object.keys(this.configuration.main_number_config).length
    // )
  },
  computed: {
    showVerticalClass() {
      return Object.keys(this.config.main_number_config).length > 1
        ? 'vertical'
        : ''
    },
    alignDataCenter() {
      return Object.keys(this.config.main_number_config).length == 1 ||
        Object.keys(this.config.main_number_config).length == 2
        ? 'alignDataCenter'
        : ''
    }
  },
  methods: {}
}
</script>

<style scoped>
.content-view-port {
  width: 100%;
  height: 100%;
}
.alignDataCenter {
  justify-content: space-evenly;
  align-items: center;
}
.vertical {
  border-left: 1px solid #cdcdcd;
  height: 55px;
  position: absolute;
  left: 50%;
}
.main-number-values {
  font-size: 1.6em;
  font-weight: 600;
}
</style>